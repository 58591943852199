<template>
  <div>
    <form @submit.prevent="manageAnualReport()">
      <a-button
        class="btn-save-temporaly"
        shape="round"
        type="primary"
        @click="saveTemporaly"
        >GUARDAR COMO BORRADOR</a-button
      >
      <step1_detail
        :loadData="loadData"
        :dataUpdate="dataUpdate"
        :view="view"
        :disbaledInput="false"
        :data_form="data_form"
        :current_step="1"
        v-if="step_query == 1"
        :handleFilesChange="handleFilesChange"
        ref="step_1"
      />
      <step2_detail
        :loadData="loadData"
        :dataUpdate="dataUpdate"
        :view="view"
        :disbaledInput="false"
        :data_form="data_form"
        :current_step="2"
        v-if="step_query == 2"
        ref="step_2"
      />
      <buttons_steps
        :step_query="step_query"
        :canceAction="canceAction"
        :nextStep="nextStep"
      />
    </form>
  </div>
</template>

<script>
import step1_detail from "@/feature/annual_report_ev/presentation/views/steps/step1_detail";
import step2_detail from "@/feature/annual_report_ev/presentation/views/steps/step2_detail";
import buttons_steps from "@/feature/annual_report_ev/presentation/partials/buttons_steps";
import AnnualReportServices from "@/feature/annual_report_ev/data/services/annual_report_services";
import { validadInput } from "../../../../core/utils/validateInputs";

export default {
  components: {
    step1_detail,
    step2_detail,
    buttons_steps,
  },
  data() {
    return {
      paramId: null,
      current_step: 1,
      step_query: 1,
      disbaledInput: false,
      errors: {},
      view: "edit",
      data_form: {
        fecha_reporte:
          JSON.parse(sessionStorage.getItem("info_informe")).responsable || "",
        entidad_vigilada: "",
        responsable:
          JSON.parse(sessionStorage.getItem("info_informe")).responsable || "",
        tiempo: "Óptimo",
        estado: "Gestionado",
        pronunciamiento_obligatorio_entidad: "",
        descripcion_pronunciamiento_obligatorio_entidad: "",
        file: [],
        periodo_cumplimientos: "",
        periodo_cumplimientos_razones: "",
        periodo_razones: "",
        descripcion_periodo_razones: "",
        pronunciamiento_general: "",
        descripcion_pronunciamiento_general: "",
        requerimientos_humanos_tecnicos: "",
        descripcion_requerimientos_humanos_tecnicos: "",
        fecha_requerimientos_humanos_tecnicos: "",
        suministro_solicitado: "",
        fecha_suministro_solicitado: "",
        descripcion_suministro_solicitado: "",
      },
      dataUpdate: {},
    };
  },
  methods: {
    handleFilesChange(selectedFiles) {
      this.data_form.file = selectedFiles;
    },
    async loadData() {
      addEventListener("popstate", () => {
        this.step_query = this.$router.currentRoute.query.step;
      });

      this.paramId = this.$route.params.id;
      const { data } = await AnnualReportServices.getReport(this.paramId);

      if (data === null) {
        this.data_form = this.data_form;
      } else {
        this.data_form = data;
      }

      return this.data_form;
    },
    async saveTemporaly() {
      const copy = Object.assign({}, this.data_form);
      copy.complete = false;

      const isCreated = await AnnualReportServices.manageReport(
        this.paramId,
        copy
      );

      if (isCreated.isSuccess === true) {
        if (this.data_form.file && this.data_form.file.length > 0) {
          const images = this.data_form.file;
          const refId = isCreated.data.document_id;
          const formData = new FormData();
          for (const element of images) {
            formData.append("file", element);
          }
          await AnnualReportServices.uploadFIlesReport(refId, formData);
        }
      }
      this.$router.push(`/dashboard/register-annual-report/`);
    },
    async validateStepOne() {
      return (this.errors = await validadInput([
        {
          key: "pronunciamiento_obligatorio_entidad",
          value: this.data_form.pronunciamiento_obligatorio_entidad,
          required:
            this.data_form.pronunciamiento_obligatorio_entidad === ""
              ? true
              : false,
          type: "radio",
        },
        {
          key: "descripcion_pronunciamiento_obligatorio_entidad",
          value: this.data_form.descripcion_pronunciamiento_obligatorio_entidad,
          required:
            this.data_form.pronunciamiento_obligatorio_entidad !== "" &&
            this.data_form.descripcion_pronunciamiento_obligatorio_entidad ===
              ""
              ? true
              : false,
          type: "textarea",
        },
        {
          key: "files",
          value: this.data_form.files,
          required: false,
          type: "files",
        },
        {
          key: "periodo_cumplimientos",
          value: this.data_form.periodo_cumplimientos,
          required: this.data_form.periodo_cumplimientos === "" ? true : false,
          type: "radio",
        },
        {
          key: "periodo_cumplimientos_razones",
          value: this.data_form.periodo_cumplimientos_razones,
          required:
            this.data_form.periodo_cumplimientos !== "" &&
            this.data_form.periodo_cumplimientos_razones === ""
              ? true
              : false,
          type: "textarea",
        },
        {
          key: "periodo_razones",
          value: this.data_form.periodo_razones,
          required: this.data_form.periodo_razones === "" ? true : false,
          type: "radio",
        },
        {
          key: "descripcion_periodo_razones",
          value: this.data_form.descripcion_periodo_razones,
          required:
            this.data_form.periodo_razones !== "" &&
            this.data_form.descripcion_periodo_razones === ""
              ? true
              : false,
          type: "textarea",
        },
        {
          key: "pronunciamiento_general",
          value: this.data_form.pronunciamiento_general,
          required:
            this.data_form.pronunciamiento_general === "" ? true : false,
          type: "radio",
        },
        {
          key: "descripcion_pronunciamiento_general",
          value: this.data_form.descripcion_pronunciamiento_general,
          required:
            this.data_form.pronunciamiento_general !== "" &&
            this.data_form.descripcion_pronunciamiento_general === ""
              ? true
              : false,
          type: "textarea",
        },
      ]));
    },
    async validateStepTwo() {
      return (this.errors = await validadInput([
        {
          key: "requerimientos_humanos_tecnicos",
          value: this.data_form.requerimientos_humanos_tecnicos,
          // required: false,
          required:
            this.data_form.requerimientos_humanos_tecnicos === ""
              ? true
              : false,
          type: "radio",
        },
        {
          key: "descripcion_requerimientos_humanos_tecnicos",
          value: this.data_form.descripcion_requerimientos_humanos_tecnicos,
          required:
            this.data_form.requerimientos_humanos_tecnicos === 1 &&
            this.data_form.descripcion_requerimientos_humanos_tecnicos === ""
              ? true
              : false,
          type: "textarea",
        },
        {
          key: "before_fecha_requerimientos_humanos_tecnicos",
          value: this.data_form.before_fecha_requerimientos_humanos_tecnicos,
          required:
            this.data_form.requerimientos_humanos_tecnicos === 1 &&
            this.data_form.before_fecha_requerimientos_humanos_tecnicos === ""
              ? true
              : false,
          type: "date",
        },
        {
          key: "suministro_solicitado",
          value: this.data_form.suministro_solicitado,
          required: false,
          // required: this.data_form.suministro_solicitado === "" ? true : false,
          // required: (this.data_form.requerimientos_humanos_tecnicos === 1 && this.data_form.suministro_solicitado === '') ? true : false,
          type: "radio",
        },
        {
          key: "after_fecha_suministro_solicitado",
          value: this.data_form.after_fecha_suministro_solicitado,
          required:
            this.data_form.suministro_solicitado === 1 &&
            this.data_form.after_fecha_suministro_solicitado <=
              this.data_form.before_fecha_requerimientos_humanos_tecnicos
              ? true
              : false,
          // required: (this.data_form.suministro_solicitado === 1 && this.data_form.after_fecha_suministro_solicitado === '') ? true : false
          //     || (this.data_form.after_fecha_suministro_solicitado <= this.data_form.before_fecha_requerimientos_humanos_tecnicos) ? true : false,
          // () => {
          //     return true;
          //     return (this.data_form.suministro_solicitado === 1 && this.data_form.after_fecha_suministro_solicitado === '')
          //         || this.data_form.after_fecha_suministro_solicitado <= this.data_form.before_fecha_requerimientos_humanos_tecnicos;
          //     // if (this.data_form.suministro_solicitado === 1 && this.data_form.after_fecha_suministro_solicitado === '') {
          //     //     return true;
          //     // } else if (String(this.data_form.after_fecha_suministro_solicitado) <= String(this.data_form.before_fecha_requerimientos_humanos_tecnicos)) {
          //     //     return true;
          //     // } else {
          //     //     return false;
          //     // }
          // },
          type: "date",
        },
        {
          key: "descripcion_suministro_solicitado",
          value: this.data_form.descripcion_suministro_solicitado,
          required:
            this.data_form.requerimientos_humanos_tecnicos === 1 &&
            this.data_form.descripcion_suministro_solicitado === ""
              ? true
              : false,
          type: "textarea",
        },
      ]));
    },
    canceAction() {
      this.$router.push(`/dashboard/register-annual-report/`);
    },
    async manageAnualReport() {
      const errors = await this.validateStepTwo();
      const resp = Object.values(errors).includes(true);
      if (resp) {
        this.errors = errors;
        console.log(this.errors);
        return;
      }

      const copy = Object.assign({}, this.data_form);
      copy.complete = true;

      const isCreated = await AnnualReportServices.manageReport(
        this.paramId,
        copy
      );

      if (isCreated.isSuccess === true) {
        if (this.data_form.file && this.data_form.file.length > 0) {
          const images = this.data_form.file;
          const refId = isCreated.data.document_id;
          const formData = new FormData();
          for (const element of images) {
            formData.append("file", element);
          }
          await AnnualReportServices.uploadFIlesReport(refId, formData);
        }
      }
    },
    async nextStep(step) {
      const errors = await this.validateStepOne();
      const response = Object.values(errors).includes(true);
      if (response) {
        this.errors = errors;
        // console.log(this.errors);
        return;
      }
      this.$router.currentRoute.params.id;
      this.current_step = step;
      this.step_query = step;
      this.$router.push(
        `/dashboard/register-annual-report/edit/${this.paramId}?step=${step}`
      );
    },
  },

  created() {
    this.paramId = this.$route.params.id;
  },
};
</script>